.home-page {
  width: 100%;
}
body{
  width: 100%;
}

header {
  display: flex;
  justify-content: center;
  position: relative;
  height: 500px;
  overflow: hidden;
  z-index: 4;
}

.navbar{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-around;
  max-height: 160px;
  position: relative;
}

.logo {
  left: 1rem;
  top: 0rem;
  width: 130px;
  height: auto;
  z-index: 4;
}

.social-icons{
  display: flex;
  gap: 10px;
  z-index: 5;
}

.tiktok{
  color: #25F4EE;
  display: flex;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 50%;
}

a {
  text-decoration: none;
}


.facebook{
  color: #1877F2;
  display: flex;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 50%;
}

header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1; 
}

header::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("/public/concrete.jpeg") center/cover no-repeat;
  z-index: 0;
}


.business-info,
.services,
.contact-info,
.image-carousel,
.image-gallery {
  margin-bottom: 40px;
}

h1{
  margin: 0;
  color: #eaeaea;
  z-index: 2;
}

.intro h2 {
  padding-bottom: 5px;
  width: 80%;
  font-size: 25px;
  font-weight: 400;
  color: #eaeaea;
}

.intro{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #1c1c1c;
  margin: 0;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
}

footer {
  text-align: center;
  margin-top: 40px;
}

.services {
  margin-top: 50px;
  padding: 0 8rem;
  box-sizing: border-box;
}

.services h2 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.services ul {
  list-style-type: none;
  margin: 0;
}

.contact-info {
background-color: #f8f9fa;
padding: 20px;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}

.contact-info h2 {
color: #333;
font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.contact-info p {
margin: 10px 0;
font-size: 16px;
color: #555;
}

.contact-items{
display: flex;
justify-content: space-evenly;
}

.contact-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;  
}

.map{
  width: 65%;
  aspect-ratio: 2;
}

.image-carousel{
  background-color: #1c1c1c;
  box-sizing:border-box;    
  padding-bottom: 3rem;
}

.image-carousel h2{
  color: #e6e6e6;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.carousel-container{
  box-sizing: border-box;
  width: 40%;
}

.services {
  margin-top: 40px;
}

.services h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.services-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.service-item {
  background-color: #9CF6F6;
  color: #040404;
  padding: 8px 16px;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.locations{
  width: 90%;    
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {

  .navbar{
    flex-direction: column;
    height: 70%;
    max-height:none;
    justify-content: space-around;
    align-self: center;
  }

  header::before {
    width: 100%;
    display: flex;
    align-items: center;   
    justify-content: center;
    position: absolute;
  }

  header::after {
    width: 100%;
    display: flex;
    align-items: center;   
    justify-content: center;
    position: absolute;
  }

  header h1{
    transform: translateY(-20px);
  }
  
  .logo {
    position: relative;
    align-self: center;
    margin-top: 3rem;
    transform: translateX(-10px);
  }

  .intro h2 {
    width: 95%;    
  }

  .services{
    padding: 0 3rem;
  }

  .services h2 {
    text-align: start;
  }

  .contact-info{
    padding: 0 3rem;
  }

  .contact-info h2{
    text-align: start;
  }

  .image-carousel{
    padding: 0 2rem 2rem 2rem;
  }

  .image-carousel h2{
    text-align: start;
    margin: 0;
  }

  .carousel-container{
    box-sizing: border-box;
    width: 100%;
  }

  .locations{
    width: 80%;    
  }
  
}

@media (max-width: 1000px) {
  .map{
    width: 95%;
    aspect-ratio: 1.5;
  }
}